import { enableProdMode, Injector } from '@angular/core';
import { RemoteLoggingService } from './app/core/services/remote-logging.service';
import { MaybeLoadLocaleProvidersFromQuerymap } from 'src/app/core/services/language-detection.service';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
/** load configuration and startup the application */
function loadConfig() {
    const http = new XMLHttpRequest();
    http.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            startApp(JSON.parse(this.responseText));
        }
    };
    http.open('GET', 'assets/environments/environment-config.' + window.location.hostname.split('.')[0] + '.json', true);
    http.send();
}
function IECheck() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const ie11 = ua.indexOf('Trident');
    return msie > 0 || ie11 > 0;
}
/** start the application, including locale providers if possible */
function startApp(config) {
    if (!IECheck()) {
        if (config && config.environment !== 'developer') {
            enableProdMode();
        }
        // tslint:disable-next-line: deprecation
        const injector = Injector.create([{
                deps: [Injector],
                provide: RemoteLoggingService,
                useClass: RemoteLoggingService
            }]);
        const logger = injector.get(RemoteLoggingService);
        __NgCli_bootstrap_2.platformBrowser([{
                provide: 'appConfig',
                useValue: config
            },]).bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, {
            // Enable ?lang={LOCAL_ID} to override browser config, otherwise autodetect
            providers: MaybeLoadLocaleProvidersFromQuerymap(logger)
        })
            .catch(err => logger.logError(err));
    }
    else {
        const strippedAddress = /[^/]+$/g;
        const strippedDomain = /^(?:[^\/]*\/){2}[^\/]+/g;
        const urlPath = window.location.href.match(strippedAddress);
        const baseDomain = window.location.href.match(strippedDomain);
        if (urlPath) {
            const path = urlPath[0];
            if (path !== 'not-supported.html' && path !== 'termsOfUse' && path !== 'privacyStatement' && path !== 'contactUs') {
                location.assign(baseDomain[0] + '/assets/not-supported.html');
            }
        }
        else {
            location.assign(window.location.href + 'assets/not-supported.html');
        }
    }
}
/** @ignore */
loadConfig();
